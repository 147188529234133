import {env} from '../../../environment'

export interface FetchRedirectOptions {
  path: string
  search: string
}

export const fetchRedirect = (options: FetchRedirectOptions) => {
  const url =
    env.getServiceHost() +
    'redirect/redirect?fallback=0&path=' +
    encodeURIComponent(options.path) +
    '&host=' +
    encodeURIComponent(env.getBaseUrl()) +
    '&search=' +
    encodeURIComponent(options.search)

  return fetch(url).then(response => response.json())
}
