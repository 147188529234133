import { useState, useEffect } from 'react'
import { fetchRedirect, FetchRedirectOptions } from './redirect_api'

export const getRedirectOptionsByLocation = (location: Location): FetchRedirectOptions => {
  return {
    path: location.pathname,
    search: location.search,
  }
}

export const useRedirectData = (options: FetchRedirectOptions) => {
  const [redirect, setRedirect] = useState(undefined)

  useEffect(() => {
    fetchRedirect(options).then(response => {
      setRedirect(response.data)
    })
  }, [options.path, options.search])

  return redirect
}
