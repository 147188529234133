import React from 'react'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { FilterModalElement } from '../../types'
import { navigate } from 'gatsby'
import InputLabel from '@mui/material/InputLabel'
import { createStyles, makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import { mapInternalKey2Slug } from '../../utils/UrlUtils'
import { useTranslation } from 'react-i18next'
import { trackEvent } from '../../utils/ArtboxoneTracking'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      minWidth: 120,
      maxWidth: 300,
    },
  }),
)

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: `90%`,
      width: 350,
    },
  },
}

interface FilterSelectOptions {
  name: string
  allValues: FilterModalElement[]
  selectedValues: string[]
  otherValues: string[]
  template: string
}

export default function FilterSelect(options: FilterSelectOptions) {
  const classes = useStyles()
  const { t } = useTranslation('translation')

  let selectedValues = options.selectedValues
  const allValues = options.allValues

  const urlSlugAll = mapInternalKey2Slug('all')

  const handleChange = (event: any) => {
    selectedValues = event.target.value

    trackEvent({
      category: options.name,
      action: 'Set',
      label: selectedValues.join('_'),
    })

    let url = options.template.replace('%s', urlSlugAll)

    let urlfilters = selectedValues.concat(options.otherValues)

    if (urlfilters.length > 0) {
      urlfilters = urlfilters.sort()
      url = options.template.replace('%s', urlfilters.join('_'))
    }

    navigate(url)
  }

  const renderValues: string[] = []
  allValues
    .filter((fme: FilterModalElement) => {
      return selectedValues.indexOf(fme.slug) !== -1
    })
    .map((fme: FilterModalElement) => {
      renderValues.push(t(fme.name.replace(':', '-*-')).replace('-*-', ':'))
    })

  if (renderValues.length == 0) {
    renderValues.push(t('Keine Kategorien ausgewählt'))
  }

  const showValues = allValues
    .filter((fme: FilterModalElement) => {
      return fme.display || selectedValues.indexOf(fme.slug) !== -1
    })
    .sort((a: FilterModalElement, b: FilterModalElement) => {
      if (
        selectedValues.indexOf(a.slug) !== -1 &&
        selectedValues.indexOf(b.slug) === -1
      ) {
        return -1
      } else if (
        selectedValues.indexOf(a.slug) === -1 &&
        selectedValues.indexOf(b.slug) !== -1
      ) {
        return 1
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      } else {
        return 1
      }
    })

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="filter-select-checkbox-label" shrink>
          {t(options.name.replace(':', '--')).replace('--', ':')}
        </InputLabel>
        <Select
          MenuProps={MenuProps}
          id="filter-select-checkbox"
          input={<Input />}
          multiple
          onChange={handleChange}
          renderValue={() => renderValues.join(', ')}
          value={selectedValues}
        >
          {showValues.map((fme: FilterModalElement) => (
            <MenuItem key={fme.slug} value={fme.slug}>
              <Checkbox checked={selectedValues.indexOf(fme.slug) !== -1} />
              <ListItemText
                primary={t(fme.name.replace(':', '--')).replace('--', ':')}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
