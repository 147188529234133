import React from 'react'
import SelectButton from '../generic/SelectButton'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

export default function SortingOrderButton(props: any) {
  const selectedKey = props.sortingOrder.sortingOrder
  const templateUrl = props.sortingOrder.template

  const { t } = useTranslation('translation')

  const options = [
    { key: 'bestseller', name: t('bestseller') },
    { key: 'new-in', name: t('new-in') },
  ]

  if (props.rising == true) {
    options.push({ key: 'rising-stars', name: t('rising-stars') })
  }

  if (props.searchRelevance == true) {
    options.push({ key: 'relevance', name: t('relevance') })
  }

  const buttonName = 'CategorySortingOrder'

  return (
    <Grid alignItems="center" container direction="column">
      <Grid item xs={12}>
        <SelectButton
          name={buttonName}
          options={options}
          replace={false}
          selectedKey={selectedKey}
          templateUrl={templateUrl}
        />
      </Grid>
    </Grid>
  )
}
